/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
}



ion-button {
  margin: 8px 16px 8px 16px;
  font-weight: 700;
}

ion-title {
  font-family: proxima-soft, sans-serif;
  font-weight: 700;
}

.segment-button-checked {
  color: black;
}

.gameDetails {
  --height: 60%;
  align-items: flex-end;
}

.howto {
  --height: 80%;
  align-items: flex-end;


}

.modal-wrapper {
  border-radius: 20px 20px 0px 0px !important;
}

.separator {
  margin: 0px 24px 0px 24px;
  color: #333333;
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #adb5bd;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

ion-icon {
  &[class*="custom-"] {
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: currentColor;
    width: 1em;
    height: 1em;
  }

  &[class*="custom-flight"] {
    mask-image: url(./assets/custom-icons/flight.svg);
  }
}


@media (prefers-color-scheme: dark) {

  p,
  h1,
  h2,
  h3,
  h4,
  span {
    color: white
  }

  ion-toolbar {
    background-color: #333333 !important;
  }

  .refresher-native {
    ion-spinner {
      color: #fcce7f !important;
    }

    .arrow-container ion-icon {
      color: #fcce7f !important;
    }
  }

  .white {
    color: #fff !important;
  }

  .beige {
    color: #fcce7f !important;
  }

}
